/*--------------------------------------------------------------------
 *                         INITIALISATION
 *--------------------------------------------------------------------
**/
import { Routes, Route } from 'react-router-dom';
import { lazy, useState, Suspense } from 'react';
import './App.css'
import './index.css'

/*--------------------------------------------------------------------
 *                              IMPORT
 *--------------------------------------------------------------------
**/
// token
import { TokenProvider } from './hook/TokenContext';
// Fixed
import Header from './fixed/Header/Header';

// Pages
const Auth = lazy(() => import("./pages/Auth"));
const Home = lazy(() => import("./pages/Home"));
const MesInformations = lazy(() => import("./pages/MesInformations"));
const MesAffectationsLazy = lazy(() => import("./pages/MesAffectations"));
const MissionsLazy = lazy(() => import("./pages/Missions"));
const MissionPostesLazy = lazy(() => import("./pages/MissionPostes"));


function App() {
  const [tokenApi, setTokenApi] = useState<string | boolean>(false);

  return (
    <TokenProvider tokenApi={tokenApi} setTokenApi={setTokenApi}>
      {
        tokenApi ? (
          <div>
            <Header setTokenApi={setTokenApi} />
            <Routes>
              <Route path='/' element={<Suspense fallback={<div>Loading...</div>}> <Home /> </Suspense>} />
              <Route path='/MesInformations' element={<Suspense fallback={<div>Loading...</div>}> <MesInformations /> </Suspense>} />
              <Route path="MesAffectations" element={<Suspense fallback={<div>Loading...</div>}> <MesAffectationsLazy /> </Suspense>} />
              <Route path="Missions" element={<Suspense fallback={<div>Loading...</div>}> <MissionsLazy /> </Suspense>} />
              <Route path="/MissionPostes/:code" element={<Suspense fallback={<div>Loading...</div>}> <MissionPostesLazy /> </Suspense>} />
              {/* <Route path='/Dashboard' element={<Dashboard />} /> */}
              {/* <Route path="*" element={<MissionsBenevoles />} />    */}
              {/* doit retourné la page 404 not Found  */}
            </Routes>
          </div>
        )
        :
        (
          <div>
            <Routes>
              <Route path='*' element={<Suspense fallback={<div>Loading...</div>}> <Auth setTokenApi={setTokenApi} /> </Suspense>} />
            </Routes>
          </div>
        )
      }
    </TokenProvider>
  );
}

export default App;