/**
 *             TYPES
 */
// Définition du type Email avec une vérification regex
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export type Email = string & { _regex: RegExp };

// Définition du type Téléphone avec une vérification regex
const telRegex = /^\d{10}$/;
export type Telephone = string & { _regex: RegExp };

/**
 *          FUNCTION
 * @param value 
 * @returns Boolean
 */
// Fonction pour vérifier si une valeur est un email valide
export function isEmail(value: string): value is Email {
    return emailRegex.test(value);
}

// Fonction pour vérifier si une valeur est un numéro de téléphone valide
export function isTelephone(value: string): value is Telephone {
    return telRegex.test(value);
}
