import { createContext, useEffect, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { getFetchAuth } from '../compoments/functions/request';

export const TokenContext = createContext<{ tokenApi: string | boolean, setTokenApi: (token: boolean | string) => void }>({
  tokenApi: false,
  setTokenApi: () => {}
});

interface TokenContextProps {
  children: ReactNode; // Utilisation du type ReactNode pour les enfants
  setTokenApi: (token: boolean | string) => void;
  tokenApi: string | boolean;
}

export const TokenProvider = ({ children, setTokenApi, tokenApi }:TokenContextProps) => {
  useEffect(() => {
    if (!localStorage.getItem('tokenApi')) {
      // si le token n'est pas présent, retourner vers la page d'auth
      setTokenApi(false);

    } else {
      // Récupérer le token depuis le localStorage
      const tokenFromSessionStorage = localStorage.getItem('tokenApi');

      // Vérifier si le token existe
      if (!tokenFromSessionStorage) {
        return console.error('Le token n\'est pas disponible dans le localStorage.');
      }

      getFetchAuth(`api/auth/verification`).then(() => {
        setTokenApi(true)
      }).catch(() => {
        setTokenApi(false)
        localStorage.removeItem('tokenApi')
      })
    }
  }, [setTokenApi, tokenApi])

  return (
    <TokenContext.Provider value={{ tokenApi, setTokenApi }}>
      {children}
    </TokenContext.Provider>
  );
};

TokenProvider.propTypes = {
  children: PropTypes.node, // Validation des props pour children
  setTokenApi: PropTypes.func,
  tokenApi: PropTypes.any,
};