export interface InitConfig {
  method: string;
  headers: Headers;
  mode: string;
  cache: string;
}

export interface InitError {
  message: string;
}

function customStringify(_key: string, value: unknown): unknown {
  // Si la valeur est un nombre ou un booléen, ne pas convertir en chaîne
  if (typeof value === 'number' || typeof value === 'boolean') {
    return value;
  }
  return value; // Sinon, renvoyer la valeur inchangée (elle sera convertie en chaîne par défaut)
}


/**---------------------------------------------------
 *                     FUNCTION
 * ---------------------------------------------------
 */
export function getInitFetch(): InitConfig | InitError {
  const tokenFromSessionStorage = localStorage.getItem('tokenApi');

  // Vérifier si le token existe
  if (!tokenFromSessionStorage) {
    console.error('Le token n\'est pas disponible dans le localStorage.');
    return {
      message: 'Le token n\'est pas disponible dans le localStorage.'
    };
  }

  const myHeaders = new Headers({
    "Authorization": `${tokenFromSessionStorage}`,
    "Access-Control-Allow-Origin": "*",
    "connection": 'keep-alive',
  });

  const myInit: InitConfig = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default',
  };

  return myInit;
}


export function patchInitFetch(body: unknown): InitConfig | InitError {
  const tokenFromSessionStorage = localStorage.getItem('tokenApi');

  // Vérifier si le token existe
  if (!tokenFromSessionStorage) {
    console.error('Le token n\'est pas disponible dans le localStorage.');
    return {
      message: 'Le token n\'est pas disponible dans le localStorage.'
    };
  }

  const myHeaders = new Headers({
    "Authorization": `${tokenFromSessionStorage}`,
    "Access-Control-Allow-Origin": "*",
    "connection": 'keep-alive',
    'Content-Type': 'application/json',
  });

  const myInit = {
    method: 'PATCH',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default',
    body: JSON.stringify(body, customStringify),
  };

  return myInit;
}

export function deleteInitFetch(body: unknown): InitConfig | InitError {
  const tokenFromSessionStorage = localStorage.getItem('tokenApi');

  // Vérifier si le token existe
  if (!tokenFromSessionStorage) {
    console.error('Le token n\'est pas disponible dans le localStorage.');
    return {
      message: 'Le token n\'est pas disponible dans le localStorage.'
    };
  }

  const myHeaders = new Headers({
    "Authorization": `${tokenFromSessionStorage}`,
    "Access-Control-Allow-Origin": "*",
    "connection": 'keep-alive',
    'Content-Type': 'application/json',
  });

  const myInit = {
    method: 'DELETE',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default',
    body: JSON.stringify(body, customStringify),
  };

  return myInit;
}

export function putInitFetch(body: unknown): InitConfig | InitError {
  const tokenFromSessionStorage = localStorage.getItem('tokenApi');

  // Vérifier si le token existe
  if (!tokenFromSessionStorage) {
    console.error('Le token n\'est pas disponible dans le localStorage.');
    return {
      message: 'Le token n\'est pas disponible dans le localStorage.'
    };
  }

  const myHeaders = new Headers({
    "Authorization": `${tokenFromSessionStorage}`,
    "Access-Control-Allow-Origin": "*",
    "connection": 'keep-alive',
    'Content-Type': 'application/json',
  });

  const myInit = {
    method: 'PUT',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default',
    body: JSON.stringify(body, customStringify),
  };

  return myInit;
}

export function postInitFetch(body: unknown): InitConfig | InitError {
  const tokenFromSessionStorage = localStorage.getItem('tokenApi');

  // Vérifier si le token existe
  if (!tokenFromSessionStorage) {
    console.error('Le token n\'est pas disponible dans le localStorage.');
    return {
      message: 'Le token n\'est pas disponible dans le localStorage.'
    };
  }

  const myHeaders = new Headers({
    "Authorization": `${tokenFromSessionStorage}`,
    "Access-Control-Allow-Origin": "*",
    "connection": 'keep-alive',
    'Content-Type': 'application/json',
  });

  const myInit = {
    method: 'POST',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default',
    body: JSON.stringify(body, customStringify),
  };

  return myInit;
}

export function postAuthInitFetch(body: unknown): InitConfig | InitError {
  const myHeaders = new Headers({
    "Access-Control-Allow-Origin": "*",
    "connection": 'keep-alive',
    'Content-Type': 'application/json',
  });

  const myInit = {
    method: 'POST',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default',
    body: JSON.stringify(body, customStringify),
  };

  return myInit;
}

export const baoConnexion = {
  postAuthInitFetch: postAuthInitFetch,
  putInitFetch: putInitFetch,
  deleteInitFetch: deleteInitFetch,
  getInitFetch: getInitFetch,
}